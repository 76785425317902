"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.UploadCSV = void 0;
var React = __importStar(require("react"));
var core_1 = require("@material-ui/core");
var react_1 = require("react");
var json_1 = require("../../utils/json");
var navigation_1 = require("../../state/navigation");
exports.UploadCSV = function (props) {
    navigation_1.Navigation.set({ title: "Upload CSV" });
    var _a = react_1.useState(false), processing = _a[0], setProcessing = _a[1];
    var _b = react_1.useState(), selectedFile = _b[0], setSelectedFile = _b[1];
    var _c = react_1.useState({
        errors: -1,
        updated: -1,
    }), processedResult = _c[0], setProcessedResult = _c[1];
    var _d = react_1.useState(""), errorMessage = _d[0], setErrorMessage = _d[1];
    var fileSelected = function (e) {
        setSelectedFile(e === null || e === void 0 ? void 0 : e.target.files[0]);
    };
    var uploadFile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, result, e_1, msg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = new FormData();
                    data.append("csv", selectedFile);
                    setProcessing(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, json_1.json.upload("/api/upload", data)];
                case 2:
                    result = _a.sent();
                    setProcessedResult(result.data);
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    msg = e_1 === null || e_1 === void 0 ? void 0 : e_1.message;
                    if (msg) {
                        setErrorMessage(msg);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setProcessing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("p", null,
                "Select a file to upload (",
                React.createElement("a", { href: "/sample.csv", target: "_blank" }, "download sample file"),
                ")")),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("input", { accept: "text/csv", style: { display: "none" }, id: "flecsv", name: "csv", type: "file", onChange: fileSelected }),
                React.createElement("label", { htmlFor: "flecsv", style: { marginRight: 15 } },
                    React.createElement(core_1.Button, { variant: "contained", component: "span", disabled: processing }, "Choose File")), selectedFile === null || selectedFile === void 0 ? void 0 :
                selectedFile.name),
            React.createElement("div", null,
                React.createElement("br", null),
                React.createElement(core_1.Button, { type: "submit", color: "primary", variant: "contained", onClick: uploadFile, disabled: processing }, "Process File")),
            React.createElement("div", { style: { paddingTop: 15 } },
                processing ? React.createElement(core_1.LinearProgress, null) : null,
                React.createElement(core_1.Box, { display: "" + (processedResult.updated === -1 ? "none" : "") },
                    React.createElement("div", { style: { fontSize: 16, fontWeight: "bold" } },
                        "Updated: ",
                        processedResult.updated,
                        React.createElement("br", null),
                        "Errors: ",
                        processedResult.errors))),
            React.createElement("div", { style: { paddingTop: 15 } },
                React.createElement(core_1.Box, { display: "" + (errorMessage === "" ? "none" : "") },
                    React.createElement("div", { style: { fontSize: 16, fontWeight: "bold", color: "red" } },
                        "Error: ",
                        errorMessage))))));
};
