"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.useStyles = exports.Rewards = void 0;
var React = __importStar(require("react"));
var core_1 = require("@material-ui/core");
var hooks_1 = require("../../state/hooks");
var formatter_1 = require("../../utils/formatter");
exports.Rewards = function (props) {
    var _a, _b;
    var classes = exports.useStyles();
    //console.log(props.match.params.token);
    var rewards = hooks_1.useRewardsToken((_b = (_a = props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.token);
    //console.log(rewards);
    var HighlightedReward = function (_a) {
        var value = _a.value, name = _a.name;
        if (typeof value === "undefined" || value == null) {
            return null;
        }
        return (React.createElement("div", { className: "" + classes.containerh },
            React.createElement("div", { className: "" + classes.highlightedReward }, name),
            React.createElement("div", { className: "" + classes.highlightedValue }, formatter_1.ToCurrency(value))));
    };
    var GrayReward = function (_a) {
        var value = _a.value, name = _a.name;
        if (typeof value === "undefined" || value == null) {
            return null;
        }
        return (React.createElement("div", { className: "" + classes.containerh },
            React.createElement("div", { className: "" + classes.grayReward }, name),
            React.createElement("div", { className: "" + classes.grayValue }, formatter_1.ToCurrency(value))));
    };
    var CurrentReward = function (_a) {
        var reward = _a.reward;
        return (React.createElement("div", null,
            React.createElement("div", { className: classes.container + " " + classes.bgGold },
                React.createElement("div", { className: "" + classes.propertyHeader },
                    reward.propertystreet,
                    ", ",
                    reward.propertyzip)),
            React.createElement(HighlightedReward, { name: "Total Effective Rewards Possible", value: reward.realizedrewards }),
            React.createElement(HighlightedReward, { name: "Effective Remaining Rewards", value: reward.remainingeffectiverewards }),
            React.createElement(HighlightedReward, { name: "Effective Earned Rewards", value: reward.effectiverewards }),
            React.createElement(GrayReward, { name: "Actual Remaining Rewards", value: reward.remainingrewards }),
            React.createElement(GrayReward, { name: "Actual Earned Rewards", value: reward.totalrewards }),
            React.createElement(GrayReward, { name: "Actual Monthly Rewards", value: reward.monthlyrewards })));
    };
    var PastReward = function (_a) {
        var reward = _a.reward;
        return (React.createElement("div", null,
            React.createElement("div", { className: classes.container + " " + classes.bgGold },
                React.createElement("div", { className: "" + classes.propertyHeader },
                    reward.propertystreet,
                    ", ",
                    reward.propertyzip)),
            React.createElement(GrayReward, { name: "Effective Earned Rewards", value: reward.pastloanseffectiverewards }),
            React.createElement(GrayReward, { name: "Actual Earned Rewards", value: reward.pastloansactualrewards })));
    };
    if (!rewards) {
        return React.createElement("p", null, "Unauthorized");
    }
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.container + " " + classes.bgGray },
            React.createElement("div", { className: "" + classes.header }, "DIRECT Home Rewards"),
            React.createElement("div", { className: "" + classes.textCenterGray },
                "Rewards information displayed is as of",
                " ",
                rewards.length > 0 && rewards[0].lastupload.split("T")[0]),
            React.createElement("div", { className: "" + classes.textCenterGray, style: { paddingTop: "1em" } }, "New DIRECT Home Rewards will appear here after account and qualifying mortgage are verified which may take up to two months."),
            React.createElement("div", { className: classes.headerCenterGray, style: { paddingTop: "1em" } }, "CURRENT QUALIFYING REWARDS")),
        rewards.map(function (reward, i) {
            return !reward.rewardsend ? React.createElement(CurrentReward, { key: i, reward: reward }) : null;
        }),
        React.createElement("a", { href: "http://directb.net/rewards", target: "_blank" },
            React.createElement("img", { src: "/images/direct-rewards.png", style: { width: "100%" } })),
        React.createElement("div", { className: classes.container + " " + classes.bgGray },
            React.createElement("div", { className: classes.headerCenterGray }, "PAST REWARDS")),
        rewards.map(function (reward, i) {
            return reward.rewardsend ? React.createElement(PastReward, { key: i, reward: reward }) : null;
        }),
        React.createElement("div", { className: classes.container + " " + classes.bgGray },
            React.createElement("div", { className: "" + classes.footerText }, "Effective rewards are achieved if you pay actual DIRECT Home Rewards amount toward your qualifying loan principal on time, each month."))));
};
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        fontFamily: "Open Sans",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        maxWidth: "960px",
        width: "100%",
        margin: "0 auto",
    },
    highlightedReward: {
        fontSize: 18,
        flex: 1,
    },
    highlightedValue: {
        fontSize: 24,
        flex: 1,
        alignSelf: "center",
        textAlign: "right",
    },
    grayReward: {
        fontSize: 16,
        color: "#929CA8",
        flex: 2,
    },
    grayValue: {
        fontSize: 16,
        color: "#929CA8",
        flex: 1,
        textAlign: "right",
        alignSelf: "center",
    },
    container: {
        padding: 15,
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    containerh: {
        padding: "10 15",
        display: "flex",
        flex: 1,
        borderBottom: "rgba(0,0,0,.1) solid 1px",
    },
    header: {
        textAlign: "center",
        flex: 1,
        fontSize: 24,
        fontWeight: "bold",
    },
    textCenterGray: {
        textAlign: "center",
        color: "#929CA8",
        fontSize: 14,
    },
    headerCenterGray: {
        textAlign: "center",
        color: "#929CA8",
        fontSize: 18,
        fontWeight: 500,
    },
    footerText: {
        color: "#929CA8",
        fontSize: 12,
    },
    propertyHeader: {
        color: "white",
    },
    bgGray: {
        backgroundColor: "#E9EBEF",
    },
    bgGold: {
        backgroundColor: "#C09C60",
    },
}); });
