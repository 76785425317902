"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.App = void 0;
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var screens_1 = require("./screens");
var SideNavLayout_1 = require("./layouts/SideNavLayout");
var Rewards_1 = require("./screens/user/Rewards");
var Login_1 = require("./screens/Login");
exports.App = function (props) {
    return (React.createElement(react_router_dom_1.BrowserRouter, null,
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(react_router_dom_1.Route, { path: "/admin" },
                React.createElement(SideNavLayout_1.SideNavLayout, null,
                    React.createElement(react_router_dom_1.Route, { exact: true, path: "/admin" },
                        React.createElement(screens_1.Dashboard, null)),
                    React.createElement(react_router_dom_1.Route, { exact: true, path: "/admin/upload" },
                        React.createElement(screens_1.UploadCSV, null)),
                    React.createElement(react_router_dom_1.Route, { exact: true, path: "/admin/data" },
                        React.createElement(screens_1.ViewData, null)))),
            React.createElement(react_router_dom_1.Route, { path: "/rewards/:token", component: Rewards_1.Rewards }),
            React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: Login_1.Login }))));
};
