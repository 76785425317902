"use strict";
exports.__esModule = true;
exports.StateNotifier = void 0;
var topics = {};
var RegisterTopic = function (topic, state) {
    topics[topic] = {
        state: state,
        subscribers: [],
    };
};
var NotifyTopic = function (topic) {
    topics[topic].subscribers.forEach(function (listener) {
        return listener(topics[topic].state);
    });
};
var Subscribe = function (topic, cb) {
    topics[topic].subscribers.push(cb);
    cb(topics[topic].state);
};
var Unsubscribe = function (topic, cb) {
    topics[topic].subscribers = topics[topic].subscribers.filter(function (_cb) { return _cb !== cb; });
};
exports.StateNotifier = {
    NotifyTopic: NotifyTopic,
    Subscribe: Subscribe,
    Unsubscribe: Unsubscribe,
    RegisterTopic: RegisterTopic,
};
