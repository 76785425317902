"use strict";
exports.__esModule = true;
exports.ToCurrency = void 0;
var formatter = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
});
exports.ToCurrency = function (value) {
    return formatter.format(value);
};
